<template>
  <div class="prime-view-container prime-left-menu fixed-full-height">
    <div class="prime-left-menu-container">
      <div class="prime-left-menu-header">
        <h5>Practice Settings:</h5>
        <h5 class="prime-left-menu-name">
          {{ $route.params.orgName }}
        </h5>
      </div>

      <router-link :to="{ name: 'general-settings' }">
        <div :class="{ 'active-item': this.$route.name === 'general-settings' }" class="margin-top-twenty prime-left-menu-item">
          <div class="prime-left-menu-title">
            General Settings
          </div>
        </div>
      </router-link>
      <router-link :to="{ name: 'task-assignments' }">
        <div :class="{ 'active-item': this.$route.name === 'task-assignments' }" class="prime-left-menu-item">
          <div class="prime-left-menu-title">
            Task Assignments
          </div>
        </div>
      </router-link>
      <router-link :to="{ name: 'notification-assignments' }">
        <div :class="{ 'active-item': this.$route.name === 'notification-assignments' }" class="prime-left-menu-item">
          <div class="prime-left-menu-title">
            Notification Assignments
          </div>
        </div>
      </router-link>
    </div>
    <ion-grid class="margin-bottom-twenty full-width full-height" v-if="loading">
      <ion-row class="full-height">
        <div class="centerItems text-muted display-flex valign full-height full-width">
          <ion-spinner name="lines" class="spinner-large valign"></ion-spinner>
        </div>
      </ion-row>
    </ion-grid>
      <PrimePage titleAlignment="left">
      </PrimePage>
        <div class="prime-settings-view full-height">
          <!-- {{currentProfessionalSettings}} -->
            <router-view></router-view>
        </div>
  </div>
</template>
<script>
import LoggedinPerson from "@/components/Global/LoggedinPerson";
import PrimePage from "@/components/Global/PrimePage";

export default {
  name: "PracticeAdminSettings",
  components: {
    PrimePage,
    LoggedinPerson
  },
  data() {
    return {
      loading: false
    };
  },
  computed: {
    user() {
      return this.$store.state.professionalSettings.professionalSettings;
    }
  },
  methods: {
    goToNamedRoute(routeName) {
      if (this.$route.query.backId) {
        this.$router.push({
          name: routeName,
          params: { id: this.$route.query.backId }
        });
      } else {
        this.$router.push({
          name: routeName
        });
      }
    }
  }
};
</script>
<style scoped>
.prime-left-menu-container {
  position: fixed;
  width: 300px;
  left: 0;
  height: 100%;
  background: white;
  padding-top: 60px;
}
.prime-left-menu-container .prime-left-menu-header {
  margin: 0px 20px 60px;
}
.prime-left-menu-container .prime-left-menu-header h5 {
  margin: 0 0 5px;
}
.prime-left-menu-container .prime-left-menu-header h5.prime-left-menu-name {
  color: var(--ion-color-primary);
  margin-bottom: 30px;
}

.prime-left-menu-container::before {
  content: "";
  position: absolute;
  background: var(--ion-color-light);
  height: 2px;
  top: 0;
  left: 20px;
  right: 20px;
}

.prime-left-menu-container a {
  color: inherit;
  text-decoration: none;
}

.prime-left-menu-item {
  padding: 15px 20px 15px 40px;
  position: relative;
  font-weight: 400;
  cursor: pointer;
}
.prime-left-menu-item.active-item:after {
  display: none;
}
.prime-left-menu-item.active-item {
  background-color: var(--color-secondary-button);
  border-right: 2px solid var(--ion-color-primary);
}
.prime-left-menu-title {
  font-size: 14px;
  font-weight: 700;
}

.prime-active-conversations-container {
  width: 320px;
}
.prime-active-conversations-container .prime-active-conversations-header {
  margin: 40px 20px 10px;
}
.prime-active-conversations-container .prime-active-conversations-header h5 {
  margin: 0 0 10px;
}
.prime-active-conversations-count-container {
  padding: 10px;
  background: var(--ion-background-color);
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 10px;
}
.prime-active-conversations-count-tag {
  background: var(--ion-color-primary);
  color: #ffffff;
  font-size: 12px;
  font-weight: 700;
  padding: 2px 6px;
  border-radius: 8px;
  margin-right: 10px;
}
.prime-active-conversations-count-label {
  font-size: 12px;
  font-weight: 600;
}

/* active list item styles */
.prime-conversation-list-container::before {
  content: "";
  position: absolute;
  background: var(--ion-color-light);
  height: 2px;
  top: 0;
  left: 20px;
  right: 20px;
}

.prime-conversation-list-item {
  padding: 15px 20px 15px 40px;
  position: relative;
  font-weight: 400;
  cursor: pointer;
}

.prime-conversation-list-item:after {
  content: "";
  position: absolute;
  background: var(--ion-color-light);
  height: 2px;
  bottom: 0;
  left: 20px;
  right: 20px;
}

.prime-conversation-list-item.active-list-item:after {
  display: none;
}

.prime-convListItem-name {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 8px;
}

.prime-convListItem-name.prime-convListItem-needsResponse {
  font-weight: 700;
}

.prime-convListItem-name.prime-convListItem-needsResponse:before {
  content: "";
  position: absolute;
  background: var(--ion-color-primary);
  border-radius: 10px;
  height: 10px;
  width: 10px;
  left: 20px;
  top: 19px;
}

.prime-convListItem-time {
  position: absolute;
  top: 15px;
  right: 20px;
  font-size: 10px;
  color: var(--ion-color-medium-shade);
}

.prime-convListItem-title {
  font-size: 13px;
  font-weight: 400;
  margin-bottom: 5px;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.prime-convListItem-title .prime-convListItem-needsResponse {
  font-weight: 500;
}

.prime-convListItem-message {
  font-size: 12px;
  color: var(--ion-color-medium-shade);
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.prime-conversation-list-item.active-list-item {
  background-color: var(--color-secondary-button);
  border-right: 2px solid var(--ion-color-primary);
}

.prime-conversation-list-item .prime-convListItem-name.prime-convListItem-needsResponse {
  font-weight: 600;
}

/* end active list item styles */

ion-label {
  overflow: unset !important;
}

.column-stretch-wrap {
  display: flex;
  flex-direction: column;
}

.column-stretch-header {
  flex: 0 0 auto;
}

.column-stretch-body {
  flex: 1 1 auto;
  position: relative; /* need this to position inner content */
  overflow-y: auto;
}

.column-stretch-footer {
  flex: 0 0 auto;
}

ion-card ion-card {
  padding: 0;
  border-radius: 6px !important;
  margin-left: -10px;
  margin-right: -10px;
}

ion-card ion-card:first-child {
  margin-top: 5px;
}

ion-card-content.prime-minimal-list {
  padding: 0 0 0 10px;
  margin-right: -10px;
}

.prime-patient-view ion-row.prime-full-height-row {
  height: calc(100vh - 165px) !important;
}

.prime-patient-view ion-row ion-col {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.prime-patient-view ion-col ion-card:first-child {
  margin-top: 20px;
}

.prime-patient-view ion-col ion-card:last-child {
  margin-bottom: 0;
}

.prime-patient-view ion-card {
  flex-shrink: 0;
}

.prime-patient-view ion-card.scroll-shrink {
  flex-shrink: 1;
  flex-grow: 1;
}

.prime-patient-view ion-card-header {
  padding: 10px 10px 10px 20px;
}

.prime-internal-scoll-card ion-card-content {
  overflow: auto;
}

.prime-internal-scoll-card ion-card {
  margin: 0 0 10px 0 !important;
  box-shadow: 0 1px 6px 0px rgba(46, 61, 73, 0.2) !important;
}

.prime-internal-scoll-card {
  display: flex;
  flex-direction: column;
}

.overflow-hidden {
  overflow: hidden;
}

/* .prime-recent-conversations {
  padding-bottom:120px;
} */

.prime-recent-conversations-footer {
  /* position: relative;
  width: 100%;
  bottom: 0; */
  height: 130px;
  padding: 0 20px 10px 20px;
  min-height: 120px;
  /* margin-left: -10px;
  margin-right: -10px; */
  border-top: 2px solid var(--ion-color-white-shade);
  background: var(--ion-color-white);
}

.prime-recent-conversations-footer .button-fw-flow {
  display: flex;
  align-items: flex-start;
  flex-wrap: nowrap;
}

.prime-recent-conversations-footer button {
  width: 100%;
  margin: 0;
}

/* .prime-recent-conversations-footer button:first-child {
  margin-right: 10px;
} */

.prime-patient-data-feed-card ion-card ion-card-title {
  font-size: 16px;
  font-weight: 600;
}

.prime-patient-data-feed-card ion-card ion-card-subtitle {
  font-size: 12px;
  font-weight: 500;
  color: var(--ion-color-medium) !important;
  margin-top: 0;
}

.prime-patient-data-feed-card ion-card ion-card-header {
  padding-bottom: 0;
}

/* Right-Hand Accordion */
.prime-rh-accordion ion-card-header {
  display: flex;
  cursor: pointer;
}

.prime-rh-accordion ion-card-title {
  flex-grow: 1;
}

.prime-rh-accordion ion-card-header .ion-card-toggle-controls {
  font-size: 20px;
  margin-bottom: -5px;
  color: var(--ion-color-medium);
}

.prime-rh-accordion ion-card-header .ion-card-toggle-controls ion-icon.closed {
  margin-right: -20px;
}

/* Past Conversations Panel */
.prime-past-conversation-container {
  cursor: pointer;
  padding: 15px 20px;
  display: flex;
}

.prime-past-conversation-icon {
  padding-top: 3px;
  padding-right: 15px;
}

.prime-past-conversation-content {
  overflow: hidden;
}
.prime-past-conversation-title {
  font-size: 16px;
  font-weight: 500;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.prime-past-conversation-time {
  font-size: 12px;
  font-weight: 500;
  color: var(--ion-color-medium);
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.disabled {
  color: var(--ion-color-medium) !important;
}

</style>
